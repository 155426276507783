import { Routes, Route } from "react-router-dom";
import Landingpage from "./Landingpage";
import Signup from "../Login/Signup";
import VerifyEmail from "../Login/VerifyEmail";
import Forgot from "../Login/Forgot";
import RequestValidationLink from "../Login/RequestValidationLink";
import Login from "../Login/Login";
import PublicLayout from "../Login/PublicLayout";
import CountryRouter from "./Country/CountryRouter";
import NotFoundPage from "../shared/404";

const NotLoggedInRouter = ({ setToken }) => {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route path="/signup" element={<Signup />} />
        <Route path="/verifyemail" element={<VerifyEmail />} />
        <Route path="/resetpassword" element={<Forgot />} />
        <Route path="/login" element={<Login setToken={setToken} />} />
        <Route
          path="/requestemailvalidation"
          element={<RequestValidationLink />}
        />
      </Route>
      {CountryRouter()}
      <Route path="/" element={<Landingpage />} />
      <Route path="/*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default NotLoggedInRouter;
