const WebLinksConfig = {
    endpoints: {
      index: "links/",
      details: "links/show/",
      edit: "links/edit",
      new: "links/",
      delete: "links/delete/"

    },
    dataField: "links"
  };
  
  
  export default WebLinksConfig;