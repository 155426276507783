import { Container, Row, Col, Card } from "react-bootstrap";
import { ReactComponent as InfoIcon } from "@serie3/mynomadplace/assets/images/countryPage/information.svg";
import { ReactComponent as InfoIconBlack } from "@serie3/mynomadplace/assets/images/countryPage/informationBlack.svg";
import { ReactComponent as TipsIcon } from "@serie3/mynomadplace/assets/images/countryPage/travelTips.svg";
import { ReactComponent as TipsIconWhite } from "@serie3/mynomadplace/assets/images/countryPage/travelTipsWhite.svg";
import { ReactComponent as LinksIcon } from "@serie3/mynomadplace/assets/images/countryPage/blogsAndLinks.svg";
import { ReactComponent as LinksIconWhite } from "@serie3/mynomadplace/assets/images/countryPage/blogsAndLinksWhite.svg";
import { Link, useLocation } from "react-router-dom";
import { useCountry } from "../../../CountryContext";
import { Trans } from "react-i18next";

const CountryLinks = () => {
  const cardLinkStyle = {
    color: "black",
    textDecoration: "none",
    padding: 0,
    margin: 0,
    width: "11.4375rem",
    height: "7.75rem",
    display: "block",
  };

  const cardTitleStyle = {
    marginTop: "1.25rem",
    fontSize: "1rem",
  };

  const country = useCountry();
  const location = useLocation();

  return (
    <Container fluid>
      <Row className="d-flex justify-content-md-center my-4">
        <Col xs={6} md={2} className="d-flex justify-content-center mt-5">
          <Link to={`/country/${country.name}`} style={cardLinkStyle}>
            <Card
              className={
                location.pathname === `/country/${country.name}`
                  ? "countryLinkSelected"
                  : "countryLink"
              }
            >
              {location.pathname === `/country/${country.name}` ? (
                <InfoIcon />
              ) : (
                <InfoIconBlack />
              )}
              <Card.Title style={cardTitleStyle}>
                <Trans i18nKey={"Country.Tabs.Info"}>Country Info</Trans>
              </Card.Title>
            </Card>
          </Link>
        </Col>
        {/**
        <Col xs={6} md={2} className="d-flex justify-content-center mt-5">
          <Link
            to={`/country/${country.name}/packinglists`}
            style={cardLinkStyle}
          >
            <Card
              style={
                location.pathname ===
                `/country/${country.name}/packinglists`
                  ? selectedCardStyle
                  : cardStyle
              }
            >
              {location.pathname ===
              `/country/${country.name}/packinglists` ? (
                <PackingListsIconWhite />
              ) : (
                <PackingListsIcon />
              )}

              <Card.Title style={cardTitleStyle}>Packing Lists</Card.Title>
            </Card>
          </Link>
        </Col>
        */}
        <Col xs={6} md={2} className="d-flex justify-content-center mt-5">
          <Link
            to={`/country/${country.name}/traveltips`}
            style={cardLinkStyle}
          >
            <Card
              className={
                location.pathname === `/country/${country.name}/traveltips`
                  ? "countryLinkSelected"
                  : "countryLink"
              }
            >
              {location.pathname === `/country/${country.name}/traveltips` ? (
                <TipsIconWhite />
              ) : (
                <TipsIcon />
              )}
              <Card.Title style={cardTitleStyle}>
                <Trans i18nKey={"Country.Tabs.TravelTips"}>Travel Tips</Trans>
              </Card.Title>
            </Card>
          </Link>
        </Col>
        {/*
        <Col xs={6} md={2} className="d-flex justify-content-center mt-5">
          <Link
            to={`/country/${country.name}/books`}
            style={cardLinkStyle}
          >
            <Card
              style={
                location.pathname === `/country/${country.name}/books`
                  ? selectedCardStyle
                  : cardStyle
              }
            >
              {location.pathname === `/country/${country.name}/books` ? (
                <BooksIconWhite />
              ) : (
                <BooksIcon />
              )}

              <Card.Title style={cardTitleStyle}>Books</Card.Title>
            </Card>
          </Link>
        </Col>
        */}
        <Col xs={6} md={2} className="d-flex justify-content-center mt-5">
          <Link to={`/country/${country.name}/links`} style={cardLinkStyle}>
            <Card
              className={
                location.pathname === `/country/${country.name}/links`
                  ? "countryLinkSelected"
                  : "countryLink"
              }
            >
              {location.pathname === `/country/${country.name}/links` ? (
                <LinksIconWhite />
              ) : (
                <LinksIcon />
              )}
              <Card.Title style={cardTitleStyle}>
                <Trans i18nKey={"Country.Tabs.Links"}>Blogs and Links</Trans>
              </Card.Title>
            </Card>
          </Link>
        </Col>
        <Col md={1} />
      </Row>
    </Container>
  );
};

export default CountryLinks;
