import { Row } from "react-bootstrap";
import HeroSections from "./HeroSections";
import BlogCard from "../../blog/BlogCard";
import blogclient from "@serie3/common/api/blogClient";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Blogsection = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const loadData = async () => {
      const response = await blogclient.get();
      if (response.status === 200) {
        setBlogPosts([...response.data.subpages]);
      }
    };
    loadData();
  }, []);

  return (
    <HeroSections title={t("Blogs.Title")} description={t("Blogs.LatestBlogposts")}>
      <Row xs={1} md={3} className="g-4 mb-5">
        {blogPosts.slice(0, 3).map((post, index) => {
          return <BlogCard post={post} key={index} />;
        })}
      </Row>
    </HeroSections>
  );
};

export default Blogsection;
