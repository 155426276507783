import { Card } from "react-bootstrap";

const CustomCard = ({ children, title }) => {
  return (
    <Card className="mt-3" style={{ borderRadius: "0.75rem"}}>
      <Card.Header
        style={{
          padding: "1rem 1.85rem 1rem 1.85rem",
          color: "#242424",
          fontSize: "1rem",
          fontStyle: "normal",
          fontWeight: "850",
          lineHeight: "normal",
          borderTopRightRadius: "0.75rem"
        }}
      >
        {title}
      </Card.Header>
      <Card.Body
        style={{
          padding: "1rem 1.85rem 1.5rem 1.85rem",
          backgroundColor: "#FCFCFC",
          borderRadius: "0.75rem"
        }}
      >
        {children}
      </Card.Body>
    </Card>
  );
};

export default CustomCard;
