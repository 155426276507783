import TravelTip from "../Entity/TravelTip";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import TravelTipsConfig from "../Resources/Endpoints";
import listHandler from "@serie3/common/Domain/listHandler";

const createTravelTipForm = (dataList, setDataFunction, countryData = null, setAdditionalData) => {
  const validationSchema = Yup.object({
    Tipp: Yup.string().required("Required"),
  });

  const onSubmit = async (values) => {
    const originalValues = { ...values };
    if (values.id > 0) {
      const country = values.land;
      values.land = { id: country.id };
      let payload = JSON.stringify(values, null, 2);
      const response = await CrudApi.edit(
        TravelTipsConfig.endpoints.edit,
        payload
      );

      let originalPayload = JSON.stringify(originalValues, null, 2);
      listHandler.updateList(
        response,
        originalPayload,
        dataList,
        setDataFunction
      );
    } else {
      values.land = {id: countryData.id}
      let payload = JSON.stringify(values, null, 2);
      const response = await CrudApi.createNew(
        TravelTipsConfig.endpoints.new,
        payload
      );
      listHandler.newToList(
        response,
        dataList,
        setDataFunction,
        null
      );

      if(response.ok){
        values.id = response.data.id;
        let additionalData = {
          id: response.data.id,
          land: {
            id: countryData.id
          }
        }
        setAdditionalData(additionalData);
      }

    }
  };

  return {
    initialValues: TravelTip,
    onSubmit: (values, actions) => {
      onSubmit(values);
    },
  };
};

const setEditData = (editData) => {
  let TravelTip = {
    id: editData.id,
    tipp: editData.tipp,
    url: editData.url,
    land: editData.land,
  };

  return TravelTip;
};

export default { createTravelTipForm, setEditData };
