import crudApi from "@serie3/common/api/crudApi";
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import endpoints from "@serie3/common/config/endpoints";
import { useTranslation } from "react-i18next";
import AuthContainer from "./AuthContainer";

const VerifyEmail = () => {
  const [registerStatus, setRegisterStatus] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const verifyData = async () => {
      const endpoint = endpoints.register.verify + window.location.search;
      const response = await crudApi.justGet(endpoint);
      if (response.status === 200) {
        setRegisterStatus(true);
      } else {
        setRegisterStatus(false);
      }
    };
    verifyData();
  }, []);

  return (
    <AuthContainer title={t('Login.Register.Title')}>
      <Row className="justify-content-md-center">
        <Col md={12} style={{ textAlign: "center" }}>
          <h3>
            {registerStatus ? (
              <span style={{ color: "white" }}>
                {t("Login.Register.Success")}
              </span>
            ) : (
              <span style={{ color: "red" }}>{t("Login.Register.Error")}</span>
            )}
          </h3>
        </Col>
      </Row>
    </AuthContainer>
  );
};

export default VerifyEmail;
