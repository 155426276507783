import { Container, Row, Col } from "react-bootstrap";
import { BsInstagram, BsTwitter, BsFacebook } from "react-icons/bs";
import Brand from "../../shared/Brand";
import { Trans } from "react-i18next";

const Footer = () => {
  return (
    <Container
      fluid
      className="mt-5 footerContainer footerMainText"
      style={{
        backgroundColor: "#333",
        color: "#fff",
        paddingTop: "2rem",
        paddingBottom: "2rem",
      }}
    >
      <Row className="justify-content-between">
        <Col md={2} className="mt-5">
          <Brand path={"/"} useBlack={false} />
          <p className="mt-3">
            <Trans i18nKey={"Footer.Description"}>
              We have lots of useful travel information and tips for you. Join
              us for free and start building our community of digital nomads
            </Trans>
          </p>
        </Col>
        <Col md={2} className="mt-5">
          <h5 className="footerTitle">Contact us</h5>
          <p className="mt-3">
            Address:
            <span className="footerContactData">
              Geleitsgasse 2, 90762 Fuerth
            </span>
          </p>
          <p>
            Email: <span className="footerContactData">felix@serie3.de</span>
          </p>
        </Col>
        <Col md={2} className="mt-5">
          <h5 className="footerTitle">Gallery</h5>
          <p className="mt-3">Is being worked on!</p>
        </Col>
        <Col md={2} className="mt-5">
          <h5 className="footerTitle">Follow us</h5>
          <p className="mt-3">
            <Trans i18nKey={"Footer.Follow"}>
              Share this with your friends and help building the biggest
              community of digital nomads on the internet
            </Trans>
          </p>
          <div>
            <BsInstagram className="me-3" />
            <BsTwitter className="me-3" />
            <BsFacebook className="me-3" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
