import CustomCard from "../Subcomponents/CustomCard";
import { Row, Col } from "react-bootstrap";
import { useCountry } from "../../../CountryContext";
import PowerPlugs from "./PowerPlugs";
import { Trans, useTranslation } from "react-i18next";

const CountryInfos = () => {
  const countryData = useCountry();
  const { t } = useTranslation();

  return (
    <>
      <h3 className="pageTitle">
        <Trans i18nKey={"Country.InformationHeader"}>Information</Trans>
      </h3>

      <CustomCard title={t("Country.Geodata.Header")}>
        <Row>
          <Col xs={6} md={3} className="countryInfo">
            <strong>
              <Trans i18nKey={"Country.Geodata.Capital"}>Capital</Trans>
            </strong>
            <p>{countryData.hauptstadt}</p>
          </Col>
          <Col xs={6} md={3} className="countryInfo">
            <strong><Trans i18nKey={"Country.Geodata.Languages"}>Languages</Trans></strong>
            <p>{countryData.sprachen}</p>
          </Col>
          <Col xs={6} md={3} className="countryInfo">
            <strong><Trans i18nKey={"Country.Geodata.Continent"}>Capital</Trans></strong>
            <p>{countryData.kontinent}</p>
          </Col>
          <Col xs={6} md={3} className="countryInfo">
            <strong><Trans i18nKey={"Country.Geodata.LargestCity"}>Largest city</Trans></strong>
            <p>{countryData.groeste_stadt === "capital" ? countryData.hauptstadt : countryData.groeste_stadt}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={3} className="countryInfo">
            <strong><Trans i18nKey={"Country.Geodata.DrivingSide"}>Driving side</Trans></strong>
            <p>{countryData.fahrseite}</p>
          </Col>
          <Col xs={6} md={3} className="countryInfo">
            <strong><Trans i18nKey={"Country.Geodata.DateFormat"}>Date format</Trans></strong>
            <p>{countryData.datumsformat}</p>
          </Col>
          <Col xs={6} md={3} className="countryInfo">
            <strong><Trans i18nKey={"Country.Geodata.TimeZones"}>Time zones</Trans></strong>
            <p>{countryData.zeit_zonen}</p>
          </Col>
          <Col xs={6} md={3} className="countryInfo">
            <strong><Trans i18nKey={"Country.Geodata.Currency"}>Currency</Trans></strong>
            <p>{countryData.currency}</p>
          </Col>
        </Row>
        <Row></Row>
      </CustomCard>

      <CustomCard title={t("Country.Geodata.Communication")}>
        <Row>
          <Col xs={6} md={3} className="countryInfo">
            <strong><Trans i18nKey={"Country.Geodata.MainLanguage"}>Main language</Trans></strong>
            <p>{countryData.sprachen}</p>
          </Col>
          <Col xs={6} md={3} className="countryInfo">
            <strong><Trans i18nKey={"Country.Geodata.CountryCode"}>Country code</Trans></strong>
            <p>+{countryData.laendervorwahl}</p>
          </Col>
          <Col xs={6} md={3} className="countryInfo">
            <strong><Trans i18nKey={"Country.Geodata.MainReligion"}>Main Religion</Trans></strong>
            <p>{countryData.hauptreligion}</p>
          </Col>
          <Col xs={6} md={3} className="countryInfo">
            <strong><Trans i18nKey={"Country.Geodata.Tld"}>Tld</Trans></strong>
            <p>.{countryData.tld}</p>
          </Col>
        </Row>
      </CustomCard>

      <CustomCard
        title={ t("Country.Geodata.Electricity") + "(" + countryData.stromspannung.trim() + "V)"}
      >
        <Row>
          <PowerPlugs countryPowerplugs={countryData.stromstecker} />
        </Row>
      </CustomCard>
    </>
  );
};

export default CountryInfos;
