import { Row, Col, Card } from "react-bootstrap";
import { ReactComponent as TipsIcon } from "@serie3/mynomadplace/assets/images/countryPage/travelTips.svg";
import { ReactComponent as LinksIcon } from "@serie3/mynomadplace/assets/images/countryPage/blogsAndLinks.svg";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const cardTitleStyle = {
    marginTop: "1.25rem",
    fontSize: "1rem",
  };
  return (
    <Row>
      <Col md={6}>
        <Link
          to={`/tips`}
          className={"cardLinkStyle"}
          style={{ width: "100%" }}
        >
          <Card className={"countryLink"}>
            <TipsIcon />
            <Card.Title style={cardTitleStyle}>Travel Tips</Card.Title>
          </Card>
        </Link>
      </Col>
      <Col>
        <Link
          to={`/links`}
          className={"cardLinkStyle"}
          style={{ width: "100%" }}
        >
          <Card className={"countryLink"}>
            <LinksIcon />
            <Card.Title style={cardTitleStyle}>Blogs, Links</Card.Title>
          </Card>
        </Link>
      </Col>
    </Row>
  );
};

export default Dashboard;
