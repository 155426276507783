import { Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import ImageLeft from "@serie3/mynomadplace/assets/images/signupPage/imageLeft.svg";
import Brand from "../shared/Brand";

const PublicLayout = () => {
  return (
    <Container fluid>
        <Row className="mt-5">
          <Col md={6} className="p-0">
            {/* Background image will go here */}
            <div
              style={{
                backgroundImage: `url(${ImageLeft})`,
                backgroundSize: "cover",
                height: "56.25rem",
              }}
            ></div>
          </Col>
          <Col md={6} style={{padding: "4.37rem"}}>
            <Brand />
            <Outlet />
          </Col>
        </Row>
    </Container>
  );
};

export default PublicLayout;
