import { Outlet } from "react-router-dom";
import CustomNavbar from "../shared/CustomNavbar";
import Footer from "../notloggedin/Subcomponents/Footer";
import FooterBar from "../notloggedin/Subcomponents/FooterBar";
import { useLocation } from "react-router-dom";

const Layout = ({ token }) => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <>
      {(path !== "/") && <CustomNavbar />}
      <Outlet />
      <Footer />
      <FooterBar />
    </>
  );
};

export default Layout;
