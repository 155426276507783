import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import HeaderBackground from "@serie3/mynomadplace/assets/images/countryPage/headerBackground.svg";
import renderFlagEmoji from "@serie3/mynomadplace/Domain/renderFlagEmoji";
import { Trans } from "react-i18next";

const CountryHeader = ({ countryData }) => {
  const headerStyle = {
    backgroundImage: `url(${HeaderBackground}), linear-gradient(to bottom, #ffffff 0%, #f0e2c3 100%)`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundColor: "#fffbe7",
    backgroundSize: "cover",
    paddingTop: "2rem",
    height: "18rem",
  };

  let translationKeyName = "CountryData." + countryData.name + ".Name";
  let translationKeyDescripton =
    "CountryData." + countryData.name + ".Description";

  return (
    <Container fluid style={headerStyle}>
      <Row>
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>{countryData.name}</Breadcrumb.Item>
          </Breadcrumb>
          <h1 className="text-center my-4 countryHeaderInfo">
            <Trans i18nKey={translationKeyName}></Trans>{" "}
            {renderFlagEmoji(countryData.flagcode)}
          </h1>
          <p className="text-center mb-4 countryDescription">
            <Trans i18nKey={translationKeyDescripton}></Trans>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default CountryHeader;
