import crudApi from "@serie3/common/api/crudApi";
import { useEffect, useState } from "react";
import WebLinksConfig from "@serie3/mynomadplace/Modules/Weblinks/Resources/Endpoints";
import { Row, Col } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import listHandler from "@serie3/common/Domain/listHandler";

const Weblinks = () => {
  const [weblinks, setWeblinks] = useState();

  useEffect(() => {
    const fetchLinks = async () => {
      await crudApi.getAll(
        WebLinksConfig.endpoints.index,
        setWeblinks,
        WebLinksConfig.dataField
      );
    };

    fetchLinks();
  }, []);

  const deleteAction = (index, weblinkId) => {
    const deleteUrl = WebLinksConfig.endpoints.delete;
    listHandler.deleteFromList(
      index,
      crudApi.del(deleteUrl, weblinkId),
      setWeblinks,
      weblinks
    );
  };

  return weblinks
    ? weblinks.map((link, index) => {
        return (
          <Row key={link.id}>
            <Col>
              <b>{link.land ? link.land.name : "no info"}:</b> {link.url}
              <FaTrash
                style={{ color: "red", cursor: "pointer", marginLeft: "1.25rem"}}
                onClick={() => {
                  deleteAction(index, link.id);
                }}
              />
            </Col>
          </Row>
        );
      })
    : "Loading";
};

export default Weblinks;
