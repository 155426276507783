import { useCountry } from "../../../CountryContext";
import { Row, Col, Button } from "react-bootstrap";
import useToken from "../../../security/useToken";
import { useState } from "react";
import LinkForm from "../../loggedin/Forms/LinkForm";
import { Trans } from "react-i18next";

const Links = () => {
  const countryData = useCountry();
  const [links, setLinks] = useState(countryData.weblinks);
  const [showForm, setShowForm] = useState(false);
  const { token } = useToken();

  let translationKeyName = "CountryData." + countryData.name + ".Name";
  
  return (
    <>
      <h3 className="pageTitle">
        <Trans i18nKey={"Country.Links.Header"}>Usefull links for </Trans>
        <Trans i18nKey={translationKeyName}>{countryData.name} </Trans>
      </h3>
      {token && (
        <>
          <LinkForm
            show={showForm}
            setShow={setShowForm}
            dataList={links}
            setDataList={setLinks}
            countryData={countryData}
          />
          <Row className="mb-3">
            <Col style={{ paddingTop: "6px", fontWeight: "bold" }}>
              <Trans i18nKey={"Country.Links.DoFollowLink"}>
                Get yor do-follow backlink:
              </Trans>
            </Col>
            <Col style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  setShowForm(true);
                }}
              >
                <Trans i18nKey={"Country.Links.DoFollowLinkButtonText"}>
                  Link your post/video/socialmedia account
                </Trans>
              </Button>
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col>
          <ul>
            {links.map((link) => {
              return (
                <li>
                  <a href={link.url} target="_blank" rel="noreferrer">
                    {link.url}
                  </a>
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>
    </>
  );
};

export default Links;
