import Logowhite from "@serie3/mynomadplace/assets/images/logowhite.png";
import Logoblack from "@serie3/mynomadplace/assets/images/logoblack.png";
import Navbar from "react-bootstrap/Navbar";

const Brand = ({ path, useBlack = true }) => {
  const dynamicStyle = {
    color: useBlack ? "black" : "#D9DDE3",
    "@media (maxWidth: 640px)": {
      // CSS media query for mobile devices
      color: "black", // always black on mobile devices
    },
  };

  return (
    <Navbar.Brand
      href="/"
      style={{
        ...dynamicStyle,
        display: "flex",
        alignItems: "center", // This is good for vertical alignment of your logo and text within Navbar.Brand
        marginRight: "7.44rem",
        padding: "0",
        fontWeight: "bolder",
      }}
    >
        <img
          src={useBlack ? Logoblack : Logowhite}
          style={{ height: "2rem", marginTop: "-5px", marginRight: "0.5rem" }}
          alt="Logo"
        />
        my
        <span style={{ color: "#FFA500" }}>nomadplace</span>

    </Navbar.Brand>
  );
};

export default Brand;
