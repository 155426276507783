import { Row, Col, Card } from "react-bootstrap";
import useToken from "../../../security/useToken";
import renderFlagEmoji from "@serie3/mynomadplace/Domain/renderFlagEmoji";
import { FaTrash, FaPen } from "react-icons/fa";
import { useEffect, useState } from "react";
import crudApi from "@serie3/common/api/crudApi";
import TravelTipsConfig from "@serie3/mynomadplace/Modules/TravelTips/Resources/Endpoints";

const TipCard = ({
  tip,
  setEditTip,
  setShowForm,
  triggerRerender = null,
  deleteAction = null,
}) => {
  const { token } = useToken();
  const [tipPicture, setTipPicture] = useState(
    "https://felixtravelblog.de/fileadmin/templates/felix/travelblog/template/images/backgroundTravelBlog.jpg"
  );
  const location = window.location;

  useEffect(() => {
    const fetchPicture = async () => {
      let previewUrl = TravelTipsConfig.endpoints.pic + tip.id;
      const response = await crudApi.getBinary(previewUrl);
      if (response.status === 200) {
        const url = window.URL.createObjectURL(response.data);
        setTipPicture(url);
      }
    };

    fetchPicture();
  }, [triggerRerender]);

  return (
    <Col
      style={{
        color: "#6F7787",
        fontSize: "0.875rem",
        fontWeight: "350",
        lineHeight: "140%" /* 1.225rem */,
      }}
      className="mb-3"
      md={6}
    >
      <Card
        style={{
          height: "24.8125rem",
          borderRadius: "0.75rem",
        }}
      >
        <Card.Img
          variant="top"
          src={tipPicture}
          style={{
            minHeight: "12.5rem",
            maxHeight: "12.5rem",
            borderRadius: "0.75rem 0.75rem 0rem 0rem",
            marginBottom: "0.75rem",
          }}
        />
        <Card.Body className="d-flex flex-column">
          {token && location.pathname === "/tips" && (
            <Row className="mb-3">
              <Col style={{ fontSize: "small" }}>
                {tip.land &&
                  renderFlagEmoji(tip.land.flagcode) + " " + tip.land.name}
              </Col>
              <Col style={{ textAlign: "right" }}>
                <FaPen
                  style={{
                    color: "green",
                    marginLeft: "1.25rem",
                    marginRight: "1.25rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditTip(tip);
                    setShowForm(true);
                  }}
                />
                <FaTrash
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={deleteAction}
                />
              </Col>
            </Row>
          )}
          <Card.Title
            style={{ fontSize: "1rem", fontWeight: "850" }}
          ></Card.Title>
          <Card.Text
            style={{
              fontSize: "0.875rem",
              color: "#6F7787",
              fontWeight: "350",
              minHeight: "5rem",
              maxHeight: "5rem",
              overflow: "hidden",
            }}
          >
            {tip.tipp}
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default TipCard;
