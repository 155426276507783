const renderFlagEmoji = (flagCode) => {
    if(!flagCode){
        return;
    }
    // Split the flag code at the space, converting each part from hex to an integer,
    // and then to a Unicode character
    const codePoints = flagCode.split(' ').map(cp => {
        const num = parseInt(cp, 16);
        if (!isNaN(num)) {
            return num;
        } else {
            console.error("Invalid hexadecimal in flagCode:", cp);
            return null; // Or handle this case as you see fit
        }
    }).filter(cp => cp !== null); // This will remove any null values from the array
    
    return String.fromCodePoint(...codePoints);
};

export default renderFlagEmoji;