import background from "@serie3/mynomadplace/assets/images/landingpageBackgroundSmall.webp";
import Container from "react-bootstrap/esm/Container";
import CustomNavbar from "../shared/CustomNavbar";
import TravelDestinations from "./Subcomponents/TravelDestinations";
import Features from "./Subcomponents/Features";
import Blogsection from "./Subcomponents/BlogSection";
import NumberSection from "./Subcomponents/NumberSection";
import Search from "./Subcomponents/Search";


const Landingpage = () => {
  const containerBackground = {
    // Combine gradient and image in the background property
    background: `linear-gradient(90deg, rgba(36, 36, 36, 0.75) 0%, rgba(36, 36, 36, 0.00) 100%), url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return (
    <>
      <Container
        fluid
        style={containerBackground}
        className="backgroundContainer"
      >
        <CustomNavbar />
        <Search />
      </Container>
      <Features />
      <NumberSection />
      <TravelDestinations />
      <Blogsection />
    </>
  );
};

export default Landingpage;
