import { Form } from "react-bootstrap";

const TextArea = ({ formik = null, formClass= null, label=null, valueName, rows=3 }) => {
  return (
    <Form.Group
      className={formClass ? formClass : "form-inline"}
      controlId={valueName}
    >
       {label ? <Form.Label>{label}</Form.Label> : null}
      <Form.Control
        placeholder={label}
        as="textarea"
        rows={rows ? rows :3}
        name={valueName}
        value={formik.values[valueName]}
        onChange={formik.handleChange}
        isInvalid={!!formik.errors[valueName]}
        style={{ width: "100%" }}
      />
    </Form.Group>
  );
};

export default TextArea;
