import { Row, Col, Container } from "react-bootstrap";
const HeroSections = ({ children, title, description }) => {
  return (
    <Container fluid className="backgroundContainer moreContainer">
      <Row>
        <Col className="text-center more">More</Col>
      </Row>
      <Row className="mb-5">
        <Col className="text-center moreHeader">
          <h1 className="moreTitle">{title}</h1>
          <Col className="moreDescription">{description}</Col>
        </Col>
      </Row>
      {children}
    </Container>
  );
};

export default HeroSections;
