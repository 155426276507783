import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const SaveButton = ({ formik=null, buttonText = null, submitFunction = null }) => {
  const { t } = useTranslation();

  buttonText = buttonText ? buttonText : t("Backend.Buttons.Save");

  return (
    <Button
      onClick={(e) => {
        submitFunction && submitFunction(e);
        formik && formik.handleSubmit(e);

        e.preventDefault();
      }}
    >
      {buttonText}
    </Button>
  );
};

export default SaveButton;
