import "@serie3/common/Assets/css/dropzone.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import useToken from "./security/useToken";
import AuthContext from "@serie3/common/auth/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotLoggedInRouter from "./components/notloggedin/NotLoggedinRouter";
import BlogList from "./components/blog/BlogList";
import Blogpost from "./components/blog/Blogpost";
import BackendLayout from "./components/backend/BackendLayout";
import Layout from "./components/shared/Layout";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";

function App() {
  const { token, setToken } = useToken();

  return (
    <div className="w-100 min-vh-100 d-flex flex-column align-items-center">
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AuthContext.Provider value={{ token, setToken }}>
        <Router>
          <Routes>
            <Route element={<Layout token={token} />}>
              <Route path="/blog" element={<BlogList />} />
              <Route path="/blog/:splat" element={<Blogpost />} />

              {!token && (
                <Route
                  path="/*"
                  element={
                    <NotLoggedInRouter setToken={setToken} token={token} />
                  }
                />
              )}

              <Route path="/*" element={<BackendLayout />} />
            </Route>
          </Routes>
        </Router>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
