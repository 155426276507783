import Weblink from "../Entity/Weblink";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import WebLinksConfig from "../Resources/Endpoints";
import listHandler from "@serie3/common/Domain/listHandler";

const createWebLinkForm = (
  dataList,
  setDataFunction,
  countryData = null,
  setAdditionalData
) => {
  const validationSchema = Yup.object({
    Url: Yup.string().required("Required"),
  });

  const onSubmit = async (values) => {
    const originalValues = { ...values };

    values.land = { id: countryData.id };
    let payload = JSON.stringify(values, null, 2);
    const response = await CrudApi.createNew(
      WebLinksConfig.endpoints.new,
      payload
    );
    listHandler.newToList(response, dataList, setDataFunction, null);

    if (response.ok) {
      values.id = response.data.id;
      let additionalData = {
        id: response.data.id,
        land: {
          id: countryData.id,
        },
      };
    }
  };

  return {
    initialValues: Weblink,
    onSubmit: (values, actions) => {
      onSubmit(values);
    },
  };
};

const setEditData = (editData) => {
  let Weblink = {
    id: editData.id,
    url: editData.url,
    land: editData.land,
  };

  return Weblink;
};

export default { createWebLinkForm, setEditData };
