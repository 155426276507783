import AIcon from "@serie3/mynomadplace/assets/images/stromstecker/a.png";
import BIcon from "@serie3/mynomadplace/assets/images/stromstecker/b.png";
import CIcon from "@serie3/mynomadplace/assets/images/stromstecker/c.png";
import DIcon from "@serie3/mynomadplace/assets/images/stromstecker/d.png";
import EIcon from "@serie3/mynomadplace/assets/images/stromstecker/e.png";
import FIcon from "@serie3/mynomadplace/assets/images/stromstecker/f.png";
import GIcon from "@serie3/mynomadplace/assets/images/stromstecker/g.png";
import HIcon from "@serie3/mynomadplace/assets/images/stromstecker/h.png";
import IIcon from "@serie3/mynomadplace/assets/images/stromstecker/i.png";
import JIcon from "@serie3/mynomadplace/assets/images/stromstecker/j.png";
import KIcon from "@serie3/mynomadplace/assets/images/stromstecker/k.png";
import LIcon from "@serie3/mynomadplace/assets/images/stromstecker/l.png";
import MIcon from "@serie3/mynomadplace/assets/images/stromstecker/m.png";
import NIcon from "@serie3/mynomadplace/assets/images/stromstecker/n.png";
import { Col } from "react-bootstrap";

const PowerPlugs = ({ countryPowerplugs }) => {
  const powerPlugs = countryPowerplugs.split(",");

  const description = (type) => {
    return "Powerplug Type " + type;
  };

  const colStyle = {
    textAlign: "center",
    fontWeight: "bold",
  };

  const powerPlugStyle = {
    maxWidth: "50%",
    height: "auto",
    borderRadius: "0.75rem",
    brorder: "1px solid #000",
  };

  return (
    <>
      {powerPlugs.map((type, index) => {
        switch (type) {
          case "A":
            return (
              <Col md={4} style={colStyle} key={index}>
                <img
                  src={AIcon}
                  alt={description(type)}
                  style={powerPlugStyle}
                />
                <br />
                Type {type}
              </Col>
            );
          case "B":
            return (
              <Col md={4} style={colStyle} key={index}>
                <img
                  src={BIcon}
                  alt={description(type)}
                  style={powerPlugStyle}
                />
                <br />
                Type {type}
              </Col>
            );
          case "C":
            return (
              <Col md={4} style={colStyle} key={index}>
                <img
                  src={CIcon}
                  alt={description(type)}
                  style={powerPlugStyle}
                />
                <br />
                Type {type}
              </Col>
            );
          case "D":
            return (
              <Col md={4} style={colStyle} key={index}>
                <img
                  src={DIcon}
                  alt={description(type)}
                  style={powerPlugStyle}
                />
                <br />
                Type {type}
              </Col>
            );
          case "E":
            return (
              <Col md={4} style={colStyle} key={index}>
                <img
                  src={EIcon}
                  alt={description(type)}
                  style={powerPlugStyle}
                />
                <br />
                Type {type}
              </Col>
            );
          case "F":
            return (
              <Col md={4} style={colStyle} key={index}>
                <img
                  src={FIcon}
                  alt={description(type)}
                  style={powerPlugStyle}
                />
                <br />
                Type {type}
              </Col>
            );
          case "G":
            return (
              <Col md={4} style={colStyle} key={index}>
                <img
                  src={GIcon}
                  alt={description(type)}
                  style={powerPlugStyle}
                />
                <br />
                Type {type}
              </Col>
            );
          case "H":
            return (
              <Col md={4} style={colStyle} key={index}>
                <img
                  src={HIcon}
                  alt={description(type)}
                  style={powerPlugStyle}
                />
                <br />
                Type {type}
              </Col>
            );
          case "I":
            return (
              <Col md={4} style={colStyle} key={index}>
                <img
                  src={IIcon}
                  alt={description(type)}
                  style={powerPlugStyle}
                />
                <br />
                Type {type}
              </Col>
            );
          case "J":
            return (
              <Col md={4} style={colStyle} key={index}>
                <img
                  src={JIcon}
                  alt={description(type)}
                  style={powerPlugStyle}
                />
                <br />
                Type {type}
              </Col>
            );
          case "K":
            return (
              <Col md={4} style={colStyle} key={index}>
                <img
                  src={KIcon}
                  alt={description(type)}
                  style={powerPlugStyle}
                />
                <br />
                Type {type}
              </Col>
            );
          case "L":
            return (
              <Col md={4} style={colStyle} key={index}>
                <img
                  src={LIcon}
                  alt={description(type)}
                  style={powerPlugStyle}
                />
                <br />
                Type {type}
              </Col>
            );
          case "M":
            return (
              <Col md={4} style={colStyle} key={index}>
                <img
                  src={MIcon}
                  alt={description(type)}
                  style={powerPlugStyle}
                />
                <br />
                Type {type}
              </Col>
            );
          case "N":
            return (
              <Col md={4} style={colStyle} key={index}>
                <img
                  src={NIcon}
                  alt={description(type)}
                  style={powerPlugStyle}
                />
                <br />
                Type {type}
              </Col>
            );
          default:
            return "";
        }
      })}
    </>
  );
};

export default PowerPlugs;
