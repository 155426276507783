import { create } from "apisauce";
import getSettings from "../config/settings";

const settings = getSettings();

const blogClient = create({
  baseURL: settings.blogApiUrl,
});

blogClient.addResponseTransform((response) => {
  switch (response.status) {
    case 500:
      window.location.replace("/uuuups");
      break;
    case 404:
      window.location.replace("/notfound");
      break;
  }
});

export default blogClient;
