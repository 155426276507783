import { Col, Card, Button } from "react-bootstrap";
import getSettings from "@serie3/common/config/settings";
import { Link } from "react-router-dom";

const BlogCard = ({ post = null }) => {
  const settings = getSettings();

  if (!post) {
    return null; // Early return if post is undefined or null
  }

  const imageUrl = post.image
    ? settings.blogUrl + post.image
    : "https://felixtravelblog.de/fileadmin/templates/felix/travelblog/template/images/backgroundTravelBlog.jpg";
  const slug = post.slug.replace("blogapi", "blog");

  return (
    <Col
      style={{
        color: "#6F7787",
        fontSize: "0.875rem",
        fontWeight: "350",
        lineHeight: "140%" /* 1.225rem */,
      }}
    >
      <Link to={slug} className="postIndexLink">
        <Card
          style={{
            height: " 26.8125rem",
            borderRadius: "0.75rem"
          }}
        >
          <Card.Img
            variant="top"
            src={imageUrl}
            style={{
              minHeight: "12.5rem",
              maxHeight: "12.5rem",
              borderRadius: "0.75rem 0.75rem 0rem 0rem",
              marginBottom: "0.75rem",
            }}
          />
          <Card.Body className="d-flex flex-column">
            <Card.Title style={{fontSize: "1rem", fontWeight: "850"}}>{post.title}</Card.Title>
            <Card.Text style={{fontSize: "0.875rem", color: "#6F7787", fontWeight: "350", minHeight: "5rem", maxHeight: "5rem", overflow: "hidden"}}>{post.description}</Card.Text>
            <Button className="mt-auto roundbutton">Read</Button>
          </Card.Body>
        </Card>
      </Link>
    </Col>
  );
};

export default BlogCard;
