import { Row, Col, Button } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import renderFlagEmoji from "@serie3/mynomadplace/Domain/renderFlagEmoji";
import countryData from "@serie3/mynomadplace/Domain/countryData.json";
import { Typeahead } from "react-bootstrap-typeahead";
import { useTranslation, Trans } from "react-i18next";
import i18n from "../../../i18n";

const Search = ({ subtitle, title, description }) => {
  const [name, setName] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const countryContainerStyle = {
    display: "flex", // Enables flexbox layout
    alignItems: "center", // Aligns items vertically in the center
    justifyContent: "space-between", // Distributes space between and around content items
  };

  const subHeader = subtitle ? subtitle : t("Subslogan");

  const titleText = title ? title : "MyNomadoplace";
  const descriptionText = description ? description : t("Slogan");
  console.log(i18n.language);

  return (
    <Row style={{ marginTop: "5rem" }}>
      <Col>
        <Row>
          <Col
            style={{
              color: "#FFA500",
              fontSize: "1rem",
              fontWeight: "500",
              lineHeight: "normal",
              marginBottom: "0.75rem",
            }}
          >
            {subHeader}
          </Col>
        </Row>
        <Row>
          <Col>
            <h1
              style={{
                textTransform: "uppercase",
                color: "#fff",
                marginBottom: "0.75rem",
                fontFamily: "Times New Roman",
              }}
            >
              {titleText}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              color: "#fff",
              maxWidth: "40rem",
              marginBottom: "2rem",
            }}
          >
            {descriptionText}
          </Col>
        </Row>
        <Col className="countryBox">
          <Row>
            <Col>
              <Trans i18nKey="ChooseCountry">Choose Country</Trans>
            </Col>
          </Row>
          <Row>
            <Col style={countryContainerStyle}>
              <Typeahead
                onChange={(option) => {
                  if (typeof option[0] !== "undefined") {
                    setName(option[0].name);
                  }
                }}
                className="countryDropdown"
                id={"countrylist"}
                labelKey={(option) =>
                  i18n.language === "de"
                    ? `${option.german_name} ${renderFlagEmoji(
                        option.flagcode
                      )}`
                    : `${option.name} ${renderFlagEmoji(option.flagcode)}`
                }
                options={countryData.countryData}
              />

              <Button
                className="roundbutton"
                onClick={() => {
                  navigate("/country/" + name);
                }}
                disabled={!name}
              >
                Go!
              </Button>
            </Col>
          </Row>
        </Col>
      </Col>
    </Row>
  );
};

export default Search;
