import NewModal from "../../shared/NewModal";
import { useFormik } from "formik";
import travelTip from "@serie3/mynomadplace/Modules/TravelTips/Entity/TravelTip";
import travelTipForm from "@serie3/mynomadplace/Modules/TravelTips/Forms/TravelTipForm";
import { Form } from "react-bootstrap";
import TextArea from "../../Form/TextArea";
import SaveButton from "../../shared/Buttons/SaveButton";
import FileDropzone from "../../shared/FileDropzone";
import TravelTipsConfig from "@serie3/mynomadplace/Modules/TravelTips/Resources/Endpoints";
import { useState, useEffect } from "react";

const TravelTipForm = ({
  editData = null,
  show,
  setShow,
  dataList,
  setDataList,
  countryData,
  setTriggerRerender,
}) => {
  const [additionalData, setAdditionalData] = useState();

  const setData = () => {
    setTriggerRerender(Math.random());
  };

  useEffect(() => {
    let additionalData = editData && {
      id: editData.id,
      land: {
        id: editData.land.id,
      },
    };
    setAdditionalData(additionalData);
  }, [editData]);

  const editTravelTipForm = travelTipForm.createTravelTipForm(
    dataList,
    setDataList,
    countryData,
    setAdditionalData
  );
  const formik = useFormik(editTravelTipForm);

  const Save = () => {
    return <SaveButton formik={formik} />;
  };

  return (
    <NewModal
      show={show}
      setShow={setShow}
      ExtraButton={Save}
      onShow={() => {
        editData
          ? formik.setValues(travelTipForm.setEditData(editData))
          : formik.setValues(travelTip);
      }}
      onExit={() => {
        formik.resetForm({ values: { travelTip } });
      }}
    >
      {additionalData ? (
        <FileDropzone
          uploadUrl={TravelTipsConfig.endpoints.edit}
          edit={true}
          additionalData={additionalData}
          setName={false}
          setData={setData}
        />
      ) : (
        "Save your Tip to upload a picture"
      )}
      <Form>
        <TextArea formik={formik} valueName={"tipp"} rows={6} />
      </Form>
    </NewModal>
  );
};

export default TravelTipForm;
