import { Route } from "react-router-dom";
import CountryLayout from "../CountryLayout";

import CountryInfos from "./CountryInfos";
import TravelTips from "./Traveltips";
import Links from "./Links";

const CountryRouter = () => {
  return (
    <Route path="/country/:country" element={<CountryLayout />}>
      <Route
        path="/country/:country"
        element={<CountryInfos />}
        name="baseinfos"
      />
      <Route
        path="/country/:country/traveltips"
        element={<TravelTips />}
        name="traveltips"
      />
      <Route 
        path="/country/:country/links" 
        element={<Links />}
        name="links"
      />
    </Route>
  );
};

export default CountryRouter;
