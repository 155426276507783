import Nav from "react-bootstrap/Nav";
import { Button, Container, Dropdown, Navbar } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import i18n from "../../i18n";
import Brand from "./Brand";
import useToken from "../../security/useToken";

const CustomNavbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { splat } = useParams();

  const { token, logout, roles } = useToken();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const location = useLocation();
  const path = location.pathname;

  const useBlack = path === "/" ? true : false;

  const navbarStyle = {
    color: "black",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center", // Center everything vertically
    height: "5rem",
  };

  const dynamicStyle = {
    color: useBlack === true ? "#D9DDE3" : "black",
    "@media (maxWidth: 640px)": {
      // CSS media query for mobile devices
      color: "black", // always black on mobile devices
    },
  };

  const signupButtonStyle = {
    background: useBlack === true ? "rgba(255, 255, 255, 0.10)" : "#FFA500",
  };

  const loginButtonStyle = {
    background: useBlack ? "rgba(255, 255, 255, 0.10)" : "#FFF6E6",
    color: useBlack === true ? "#fff" : "#242424",
  };

  const dropDownColor = {
    color: useBlack === true ? "#fff" : "#242424",
    background: "none",
    "@media (maxWidth: 640px)": {
      // CSS media query for mobile devices
      color: "black", // always black on mobile devices
    },
  };

  const LoggedInMenu = () => {
    return (
      <>
        <Dropdown
          style={{
            float: "right",
          }}
        >
          <Dropdown.Toggle
            id="dropdown-basic"
            style={{ ...signupButtonStyle, border: "none" }}
          ></Dropdown.Toggle>
          <Dropdown.Menu className="languageDropDown">
            {roles.includes("ROLE_ADMIN") && (
              <Dropdown.Item
                style={{ ...dropDownColor }}
                onClick={() => navigate("/admindashboard")}
              >
                <span className="fi fi-de fis" />
                &nbsp; {t("Backend.Navigation.Admindashboard")}
              </Dropdown.Item>
            )}
            <Dropdown.Item
              style={{ ...dropDownColor }}
              onClick={() => navigate("/dashboard")}
            >
              <span className="fi fi-de fis" />
              &nbsp; {t("Backend.Navigation.Dashboard")}
            </Dropdown.Item>
            <div className="dropdown-divider" />
            <Dropdown.Item
              style={{ ...dropDownColor }}
              onClick={() => navigate("/links")}
            >
              <span className="fi fi-us fis" />
              &nbsp; {t("Backend.Navigation.Links")}
            </Dropdown.Item>
            <Dropdown.Item
              style={{ ...dropDownColor }}
              onClick={() => navigate("/tips")}
            >
              <span className="fi fi-us fis" />
              &nbsp; {t("Backend.Navigation.Tips")}
            </Dropdown.Item>
            <div className="dropdown-divider" />
            <Dropdown.Item
              style={{ ...dropDownColor }}
              onClick={() => changeLanguage("de")}
            >
              <span className="fi fi-de fis" />
              &#x1F1E9;&#x1F1EA; &nbsp; {t("Translation.German")}
            </Dropdown.Item>
            <Dropdown.Item
              style={{ ...dropDownColor }}
              onClick={() => changeLanguage("en")}
            >
              <span className="fi fi-us fis" />
              &#x1F1EC;&#x1F1E7; &nbsp; {t("Translation.English")}
            </Dropdown.Item>
            <div className="dropdown-divider" />
            <Dropdown.Item style={{ ...dropDownColor }} onClick={logout}>
              <span className="fi fi-us fis" />
              &nbsp; {t("Logout")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const LoginButtons = () => {
    return (
      <>
        <Button
          onClick={() => navigate("/login")}
          className="navbuttons me-2"
          style={{
            ...loginButtonStyle,
            borderRadius: "1.875rem",
          }}
        >
          Login
        </Button>
        <Button
          onClick={() => navigate("/signup")}
          className="navbuttons signupButton"
          style={{
            borderTopLeftRadius: "1.875rem",
            borderBottomLeftRadius: "1.875rem",
            ...signupButtonStyle,
          }}
        >
          {t("Signup.Signup")}
        </Button>
        <Dropdown
          style={{
            float: "right",
          }}
        >
          <Dropdown.Toggle
            id="dropdown-basic"
            className="navbuttons languageButton"
            style={{ ...signupButtonStyle }}
          ></Dropdown.Toggle>
          <Dropdown.Menu className="languageDropDown">
            <Dropdown.Item
              style={{ ...dropDownColor }}
              onClick={() => changeLanguage("de")}
            >
              <span className="fi fi-de fis" />
              &#x1F1E9;&#x1F1EA; &nbsp; {t("Translation.German")}
            </Dropdown.Item>
            <Dropdown.Item
              style={{ ...dropDownColor }}
              onClick={() => changeLanguage("en")}
            >
              <span className="fi fi-us fis" />
              &#x1F1EC;&#x1F1E7; &nbsp; {t("Translation.English")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const NavbarContent = () => {
    return (
      <Navbar
        expand="lg"
        style={{
          ...navbarStyle,
          ...dynamicStyle,
        }}
      >
        <Brand path={path} useBlack={!useBlack} />
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav" className="navbarBackground">
          <Nav className="me-auto">
            <div className="d-flex flex-column align-items-center">
              <Nav.Link
                href="/"
                className="nav-linkActive"
                style={{ ...dynamicStyle }}
              >
                Home
              </Nav.Link>
              <span className="dot mt-1"></span>
            </div>
            <Nav.Link href="/blog" style={{ ...dynamicStyle }}>
              Blogs
            </Nav.Link>
            <Nav.Link href="/blog/aboutme" style={{ ...dynamicStyle }}>
              <Trans i18nKey={"Navigation.Aboutme"}>About me</Trans>
            </Nav.Link>
          </Nav>
          <div
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center", // Center buttons vertically
            }}
          >
            <div className="btn-group">
              {!token ? <LoginButtons /> : <LoggedInMenu />}
            </div>
          </div>
        </Navbar.Collapse>
      </Navbar>
    );
  };

  return path === "/" ? (
    <NavbarContent />
  ) : (
    <Container
      fluid
      style={!splat ? {
        boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.06)",
        marginBottom: "2rem",
      } : {}}
    >
      <NavbarContent />
    </Container>
  );
};

export default CustomNavbar;
