import { Container } from "react-bootstrap";
import CountryLinks from "./Subcomponents/CountryLinks";
import CountryHeader from "./Subcomponents/CountryHeader";
import crudApi from "@serie3/common/api/crudApi";
import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import CountryConfig from "@serie3/mynomadplace/Modules/Country/Resources/Endpoints";
import { CountryContext } from "../../CountryContext";
import { useTranslation } from "react-i18next";

const CountryLayout = () => {
  const [countryData, setCountryData] = useState(null);
  const { country } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    // Declare the async function inside the useEffect
    const fetchData = async () => {
      try {
        const data = await crudApi.getAll(
          CountryConfig.endpoints.details + "/" + country
        );
        setCountryData(data.data.countryData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the async function
    fetchData();
  }, [country]);

  if (!countryData) {
    return <div>{t("Translations.Loading")}</div>;
  }

  return (
    <CountryContext.Provider value={countryData}>
      <CountryHeader countryData={countryData} />
      <CountryLinks />
      <Container fluid className="mt-4">
        <Outlet />
      </Container>
    </CountryContext.Provider>
  );
};

export default CountryLayout;
