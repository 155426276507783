import { Card } from "react-bootstrap";

const AuthContainer = ({ children, title, description }) => {
  return (
    <Card style={{ border: "none" }}>
      <div style={{ maxWidth: "28.125rem" }}>
        <h1 className="authHeader mt-5">{title}</h1>
        <p className="authDescription">{description}</p>
        {children}
      </div>
    </Card>
  );
};

export default AuthContainer;
