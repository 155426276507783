import { Form } from "react-bootstrap";

const Checkbox = ({
  formik = null,
  label = null,
  valueName = null,
  style = null,
}) => {
  return (
    <Form.Group controlId={valueName}>
      <Form.Check
        className="form-check"
        label={label}
        style={{ ...style }}
        type="checkbox"
        name={valueName}
        checked={formik && formik.values[valueName]}
        onChange={(e) => {
          if (formik) {
            formik.handleChange(e);
            formik.setFieldValue(valueName, e.target.checked);
          }
        }}
      />
    </Form.Group>
  );
};

export default Checkbox;
