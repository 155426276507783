import { Col, Row } from "react-bootstrap";
import TextField from "../Form/TextField";
import { useTranslation } from "react-i18next";
import createRegisterForm from "@serie3/common/Form/Register/RegisterForm";
import { useState } from "react";
import { useFormik } from "formik";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthContainer from "./AuthContainer";

const Signup = () => {
  const { t } = useTranslation();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showProcess, setShowProcess] = useState(false);

  const registerForm = createRegisterForm(
    setShowSuccess,
    setShowError,
    setShowProcess
  );

  const formik = useFormik(registerForm);

  return (
    <AuthContainer title={t('Login.Register.Title')}>
      <Form className="mt-4" onSubmit={formik.handleSubmit}>
        <Row>
          <Col className="mb-3">
            <div className="authLabel">{t("Login.Username")}</div>
            <TextField
              placeholder={t("Login.Username")}
              formik={formik}
              valueName={"username"}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <div className="authLabel">{t("Login.Email")}</div>
            <TextField
              placeholder={t("Login.Email")}
              formik={formik}
              valueName={"email"}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <div className="authLabel">{t("Login.Password")}</div>
            <TextField
              placeholder={t("Login.Password")}
              formik={formik}
              valueName={"password"}
              password={true}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <div className="authLabel">{t("Login.PasswordConfirm")}</div>
            <TextField
              placeholder={t("Login.PasswordConfirm")}
              formik={formik}
              valueName={"passwordconfirmation"}
              password={true}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-grid mt-5">
              <button
                className="btn btn-lg authButtons"
                type="submit"
                onClick={() => setShowError(false)}
              >
                {t("Login.SignUpButton")}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
      <div className="d-flex justify-content-between mt-4">
        <Link
          to={"/resetpassword"}
          className="btn-link text-decoration-none mr-3"
        >
          {t("Login.ForgotLink")}
        </Link>
        <Link to={"/login"} className="btn-link text-decoration-none">
          {t("Login.Login")}
        </Link>
      </div>
      <div className="border-top pt-3 mt-3 text-center">
        {showError ? (
          <span style={{ color: "red" }}> {t("Login.Messages.Error")} </span>
        ) : (
          ""
        )}
        {showSuccess ? (
          <span style={{ color: "green" }}>{t("Login.Messages.Success")} </span>
        ) : (
          ""
        )}
        {showProcess ? t("Login.Messages.Progress") : ""}
      </div>
    </AuthContainer>
  );
};

export default Signup;
