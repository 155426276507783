import crudApi from "@serie3/common/api/crudApi";
import { useEffect, useState } from "react";
import TravelTipsConfig from "@serie3/mynomadplace/Modules/TravelTips/Resources/Endpoints";
import { Row } from "react-bootstrap";
import TipCard from "../notloggedin/Subcomponents/TipCard";
import TravelTipForm from "./Forms/TravelTipForm";
import listHandler from "@serie3/common/Domain/listHandler";

const TravelTips = () => {
  const [travelTips, setTravelTips] = useState();
  const [editTip, setEditTip] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [triggerRerender, setTriggerRerender] = useState(-1);


  useEffect(() => {
    console.log(TravelTipsConfig.endpoints.index);
    const fetchTips = async () => {
      await crudApi.getAll(
        TravelTipsConfig.endpoints.index,
        setTravelTips,
        TravelTipsConfig.dataField
      );
    };

    fetchTips();
  }, []);


  const deleteAction = (index, tipId) => {
    const deleteUrl = TravelTipsConfig.endpoints.delete;
    listHandler.deleteFromList(
      index,
      crudApi.del(deleteUrl, tipId),
      setTravelTips,
      travelTips
    );
  };

  return travelTips ? (
    <>
      <TravelTipForm
        show={showForm}
        setShow={setShowForm}
        editData={editTip}
        dataList={travelTips}
        setDataList={setTravelTips}
        setTriggerRerender={setTriggerRerender}
      />
      <Row>
        {travelTips.map((tip, index) => {
          return (
            <TipCard
              tip={tip}
              key={tip.id}
              setEditTip={setEditTip}
              setShowForm={setShowForm}
              triggerRerender={triggerRerender}
              deleteAction={() => {
                deleteAction(index, tip.id);
              }}
            />
          );
        })}
      </Row>
    </>
  ) : (
    "Loading"
  );
};

export default TravelTips;
