const TravelTipsConfig = {
    endpoints: {
      index: "tips/",
      details: "tips/show/",
      edit: "tips/edit",
      pic: "public/tips/pic/",
      new: "tips/",
      delete: "tips/delete/"
    },
    dataField: "tips"
  };
  
  
  export default TravelTipsConfig;