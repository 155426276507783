const baseURLs = {
  dev: "http://dev.mynomadplace.com",
  stage: "https://stage.mynomadplace.com",
  //demo: "https://demo.mynomadplace.com",
  prod: "https://mynomadplace.com",
};

const settings = {
  dev: {
    blogUrl: baseURLs.prod,
    baseUrl: baseURLs.dev,
    apiUrl: baseURLs.dev + "/api/",
    blogApiUrl: baseURLs.prod + "/blogapi",
    stripePubKey: "",
    pricingTables: {
      billing: "",
      crm: "",
      profile: "",
      timetracking: "",
      projects: "",
      document: "",
      jobs: "",
    },
  },
  stageing: {
    blogUrl: baseURLs.prod,
    baseUrl: baseURLs.stage,
    apiUrl: baseURLs.stage + "/api/",
    blogApiUrl: baseURLs.prod + "/blogapi/",
    stripePubKey: "",
    pricingTables: {
      billing: "",
      crm: "",
      profile: "",
      timetracking: "",
      projects: "",
      document: "",
      jobs: "",
    },
  },
  stage: {
    blogUrl: baseURLs.prod,
    baseUrl: baseURLs.prod,
    apiUrl: baseURLs.stage + "/api/",
    blogApiUrl: baseURLs.prod + "/blogapi/",
    stripePubKey: "",
    pricingTables: {
      billing: "",
      crm: "",
      profile: "",
      timetracking: "",
      projects: "",
      document: "",
      jobs: "",
    },
  },
  prod: {
    blogUrl: baseURLs.prod,
    baseUrl: baseURLs.prod,
    apiUrl: baseURLs.prod + "/api/",
    blogApiUrl: baseURLs.prod + "/blogapi/",
    stripePubKey:
      "pk_live_51NNxyqCJGCgzEfQX3LlTsrnxWZQslE2yf0YO0pnO2ZwLa7KQHfh6sZ5hawrACEYP4HmTaKHR8kIR3tOK2pZhLfQK00uNH8coRe",
    pricingTables: {
      billing: "",
      crm: "",
      profile: "",
      timetracking: "",
      projects: "",
      document: "",
      jobs: "",
    },
  },
};

const getSettings = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "dev":
      return settings.dev;
    case "stage":
      return settings.stageing;
    case "stage":
      return settings.stage;
    case "prod":
      return settings.prod;
    default:
      return settings.dev;
  }
};

export default getSettings;
