import { Row, Col, ListGroup, Card } from "react-bootstrap";
import HeroSections from "./HeroSections";
import { ReactComponent as TipsIcon } from "@serie3/mynomadplace/assets/images/landingpage/tipsIcon.svg";
import { ReactComponent as UseDataIcon } from "@serie3/mynomadplace/assets/images/landingpage/useDataIcon.svg";
import { ReactComponent as BlogIcon } from "@serie3/mynomadplace/assets/images/landingpage/blogIcon.svg";
import { useTranslation, Trans } from "react-i18next";

const Features = () => {
  const { t } = useTranslation();
  return (
    <HeroSections
      title={t("Landingpage.Features")}
      description={t("Landingpage.Description")}
    >
      <Row
        className="align-items-center"
        style={{ backgroundColor: "#FCFCFC" }}
      >
        <Col md={6} className="mb-4">
          <Card
            style={{
              padding: "2.25rem",
              border: "none",
              borderRadius: "0.75rem",
              background: "none",
            }}
          >
            <Card.Img
              style={{ borderRadius: "0.75rem", background: "none" }}
              variant="top"
              src="https://felixtravelblog.de/fileadmin/_processed_/9/d/csm_524__A283576_21ef66a0ac.jpg"
            />
          </Card>
        </Col>
        <Col md={6}>
          <ListGroup variant="flush">
            {/**
            <ListGroup.Item className="d-flex align-items-start" style={{background: "none"}}>
              <DownloadIcon className="me-2 flex-shrink-0" />
              <div>
                <span className="moreBulletpointHeader">Save and download</span>
                <p className="moreBulletpointText">
                  You can download your list as a PDF at any time using the
                  direct link. Your self-added and already ticked points will of
                  course be retained
                </p>
              </div>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex align-items-start" style={{background: "none"}}>
              <ListIcon className="me-2 flex-shrink-0" />
              <div>
                <span className="moreBulletpointHeader">
                  Choose from many pre-made lists
                </span>
                <p className="moreBulletpointText">
                  I have saved my best lists for you here. Of course, you don't
                  just have to swallow my nonsense. You can edit each list and
                  add your own items.
                </p>
              </div>
            </ListGroup.Item>
          */}
            <ListGroup.Item
              className="d-flex align-items-start"
              style={{ background: "none" }}
            >
              <TipsIcon className="me-2 flex-shrink-0" />
              <div>
                <span className="moreBulletpointHeader">
                  <Trans i18nKey={"Landingpage.Tipsheader"}>
                    Useful travel tips, tricks and links
                  </Trans>
                </span>
                <p className="moreBulletpointText">
                  <Trans i18nKey={"Landingpage.Tipsdescription"}>
                    Share your best travel tip, help others have the best trip
                    ever, or choose from a list of useful and fun links to plan
                    your trip the best way possible.
                  </Trans>
                </p>
              </div>
            </ListGroup.Item>

            <ListGroup.Item
              className="d-flex align-items-start"
              style={{ background: "none" }}
            >
              <BlogIcon className="me-2 flex-shrink-0" />
              <div>
                <span className="moreBulletpointHeader">
                  <Trans i18nKey={"Landingpage.BlogsHeader"}>
                    Submit your blog post
                  </Trans>
                </span>
                <p className="moreBulletpointText">
                  <Trans i18nKey={"Landingpage.BlogDescription"}>
                    Do you have your own travel blog? Can you provide valuable
                    travel information for others? Then get your DO follow link
                    here.
                  </Trans>
                </p>
              </div>
            </ListGroup.Item>

            <ListGroup.Item
              className="d-flex align-items-start"
              style={{ background: "none" }}
            >
              <UseDataIcon className="me-2 flex-shrink-0" />
              <div>
                <span className="moreBulletpointHeader">
                  <Trans i18nKey={"Landingpage.CoomingSoonHeader"}>
                    Use our data (Coming soon)
                  </Trans>
                </span>
                <p className="moreBulletpointText">
                  <Trans i18nKey={"Landingpage.CoomingSoonDescription"}>
                    Have your own blog and want to enrich your articles with
                    valuable data? All the data you find here can be easily
                    integrated into your blog using our WordPress plugin.
                  </Trans>
                </p>
              </div>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </HeroSections>
  );
};

export default Features;
