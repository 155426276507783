import NewModal from "../../shared/NewModal";
import { useFormik } from "formik";
import weblink from "@serie3/mynomadplace/Modules/Weblinks/Entity/Weblink";
import WeblinkForm from "@serie3/mynomadplace/Modules/Weblinks/Forms/WeblinkForm";
import { Form } from "react-bootstrap";
import SaveButton from "../../shared/Buttons/SaveButton";
import TextField from "../../Form/TextField";

const LinkForm = ({ show, setShow, dataList, setDataList, countryData }) => {
  const editLinkForm = WeblinkForm.createWebLinkForm(
    dataList,
    setDataList,
    countryData
  );

  const formik = useFormik(editLinkForm);

  const Save = () => {
    return <SaveButton formik={formik} />;
  };

  return (
    <NewModal
      show={show}
      setShow={setShow}
      ExtraButton={Save}
      onShow={() => {
        formik.setValues(weblink);
      }}
      onExit={() => {
        formik.resetForm({ values: { weblink } });
      }}
    >
      <Form>
        <TextField formik={formik} valueName={"url"} />
      </Form>
    </NewModal>
  );
};

export default LinkForm;
