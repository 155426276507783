import { Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import BlogCard from "./BlogCard";
import blogclient from "@serie3/common/api/blogClient";


const BlogList = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      const response = await blogclient.get();
      if (response.status === 200) {
        setBlogPosts([...response.data.subpages]);
      }
    };
    loadData();
  }, []);

  return (
    <Container fluid>
      <Row xs={1} md={3} className="g-4 mb-5">
        {blogPosts.map((post, index) => {
          return <BlogCard post={post} key={index} />;
        })}
      </Row>
    </Container>
  );
};

export default BlogList;
