import { useCountry } from "../../../CountryContext";
import TipCard from "../Subcomponents/TipCard";
import { Row, Col, Button } from "react-bootstrap";
import useToken from "../../../security/useToken";
import TravelTipForm from "../../loggedin/Forms/TravelTipForm";
import { useState } from "react";
import { Trans } from "react-i18next";

const TravelTips = () => {
  const countryData = useCountry();
  const [travelTips, setTravelTips] = useState(countryData.reisetipps);
  const [triggerRerender, setTriggerRerender] = useState(-1);
  const [showForm, setShowForm] = useState(false);
  const { token } = useToken();

  let translationKeyName = "CountryData." + countryData.name + ".Name";
  return (
    <>
      <h3 className="pageTitle">
        <Trans i18nKey={"Country.TravelTipsHeader"}>Travel tips for </Trans>
        <Trans i18nKey={translationKeyName}>{countryData.name} </Trans>
      </h3>
      {token && (
        <>
          <TravelTipForm
            show={showForm}
            setShow={setShowForm}
            dataList={travelTips}
            setDataList={setTravelTips}
            countryData={countryData}
            setTriggerRerender={setTriggerRerender}
          />
          <Row className="mb-3">
            <Col style={{ paddingTop: "6px" }} className="pageTitle">
              Proof you're an expert for {countryData.name}
            </Col>
            <Col
              style={{ textAlign: "right" }}
              onClick={() => {
                setShowForm(true);
              }}
            >
              <Button>
                <Trans i18nKey={"Country.WriteButton"}>
                  Write a travel Tip
                </Trans>
              </Button>
            </Col>
          </Row>
        </>
      )}
      <Row className="mt-3">
        {travelTips.map((tip, index) => {
          return (
            <TipCard tip={tip} triggerRerender={triggerRerender} key={tip.id} />
          );
        })}
      </Row>
    </>
  );
};

export default TravelTips;
