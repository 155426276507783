import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Spinner from "@serie3/common/Assets/img/spinner.gif";
import CrudApi from "@serie3/common/api/crudApi";
import Logo from "@serie3/mynomadplace/assets/images/logoblack.png";

const FileDropzone = ({
  uploadUrl,
  additionalData,
  edit,
  data = [],
  setName = true,
  setData,
  setShowPayModal = null,
}) => {
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedfiles] = useState([]);
  const [location, setLocation] = useState();
  useEffect(() => {
    const tmpLocation = window.location.hostname;
    setLocation(tmpLocation);
  }, []);

  const allowedTypes = [
    "image/jpg",
    "image/jpeg",
    "image/png",
  ];

  const assignFile = (file, cssStyle, text) => {
    const fileType = file.type;
    if (allowedTypes.includes(fileType)) {
      const previewURL = URL.createObjectURL(file);
      Object.assign(file, {
        preview: previewURL,
        cssStyle: cssStyle,
        message: text,
      });
    } else {
      Object.assign(file, {
        preview: Logo,
        cssStyle: cssStyle,
        message: "No Preview possible",
      });
    }
  };

  const getMessageIcon = (wrapper, filename) => {
    switch (wrapper) {
      case "dropzoneImageWrapper":
        return <img className="upload" src={Spinner} />;
      case "dropzoneImageWrapperOk":
        return (
          <span className="uploadOk">
            <i className="fas fa-check fa-2x" />
          </span>
        );
      case "dropzoneImageWrapperFail":
        return (
          <span className="uploadFail">
            <i className="far fa-times-circle fa-2x" />
          </span>
        );
      case "dropzoneImageWrapperFileToBig":
        return (
          <span className="uploadFail">
            File: {filename} is to big.
            Max. allowed size: 5MB
          </span>
        );
      default:
        return "";
    }
  };

  const allFiles = files.map((file, index) => {
    var name = file.name + "_" + index;
    var isImage = file.type.startsWith("image/");
    var isTextOrPdf =
      file.type === "text/plain" || file.type === "application/pdf";

    return (
      <figure
        key={name}
        className={file.cssStyle}
        tabIndex="10"
        style={{
          width: `calc(33.33% - 20px)`,
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isImage && (
          <img
            src={file.preview}
            className="dropzoneImage"
            alt="preview"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              width: "100%",
              height: "100%",
            }}
          />
        )}
        {isTextOrPdf && <object data={file.preview} alt="preview" />}
        {!isImage && !isTextOrPdf && (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div style={{ color: "grey" }}>
              No preview possible
              <br />
              <b>{file.name}</b>
            </div>
          </div>
        )}

        <figcaption className="overlay" style={{ textAlign: "right" }}>
          {getMessageIcon(file.cssStyle, file.name)}
        </figcaption>
      </figure>
    );
  });

  const handleUpload = (file, edit) => {
    if (typeof uploadUrl === "undefined") {
      uploadUrl = "/documents/";
    }

    if (uploadedFiles.indexOf(file) === -1) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        let base64data = reader.result;
        let uploadData = {
          file_string: base64data,
          file_name: file.name,
          original_name: file.name,
        };

        if (setName === true) {
          uploadData.name = file.name;
        }

        uploadData = additionalData
          ? (uploadData = { ...uploadData, ...additionalData })
          : uploadData;

        let response;
        if (typeof edit === "undefined" || edit !== true) {
          response = await CrudApi.createNew(
            uploadUrl,
            uploadData,
            setShowPayModal
          );
        } else {
          response = await CrudApi.edit(uploadUrl, uploadData);
        }

        //TODO FIX UPDATING THE VIEW WHEN A FILE IS UPLOADED
        if (response.status === 200) {
          assignFile(file, "dropzoneImageWrapperOk", "Uploaded");

          if (typeof data === "array" || typeof data === "object") {
            data.push(response.data);
          }
          if (typeof setData !== "undefined") {
            setData([...data]);
          }
        } else {
          assignFile(file, "dropzoneImageWrapperFail", "Upload failed");
        }

        let tmpUploadedFiles = uploadedFiles;
        tmpUploadedFiles.push(file);
        let tmpFiles = files;
        tmpFiles.shift(file);

        let merged = new Set(tmpUploadedFiles.concat(tmpUploadedFiles));
        setFiles([...merged]);
      };
    }
    return null;
  };

  const onDrop = (acceptedFiles, fileRejections) => {
    let newFilesInDropzone = acceptedFiles.map((file) => {
      assignFile(file, "dropzoneImageWrapper", "Uploading");
      return file;
    });

    let rejectedFiles = fileRejections.map((file) => {
      assignFile(file.file, "dropzoneImageWrapperFileToBig", "Upload failed");
      return file.file;
    });

    let tmpFiles = uploadedFiles.concat(acceptedFiles);
    let allFiles = tmpFiles.concat(rejectedFiles);
    setFiles([...allFiles]);

    newFilesInDropzone.map((file) => {
      return handleUpload(file, edit);
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxSize: 5000000,
    onDrop: onDrop,
  });

  return location !== "demo.freelancersplace.net" ? (
    
    <div {...getRootProps()} className="defaultDropzone">
      <input {...getInputProps()} />
      {!isDragActive &&
        (files.length > 0 ? (
          <div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
            {allFiles}
          </div>
        ) : (
          <span className="dropzoneText">
            <p style={{ paddingTop: "100px" }}>
              Drop Stuff here Drag and Drop files
              <br />
              or
            </p>
            <p className="btn btn-primary dropzoneButton">select</p>
          </span>
        ))}
    </div>
  ): "File upload is disabled on the demo system";
};

export default FileDropzone;
