import { Routes, Route } from "react-router-dom";
import Landingpage from "../notloggedin/Landingpage";
import CountryRouter from "../notloggedin/Country/CountryRouter";
import NotFoundPage from "../shared/404";
import Dashboard from "../loggedin/Dashboard";
import TravelTips from "../loggedin/Traveltips";
import Weblinks from "../loggedin/Weblinks";
import AdminDashboard from "../loggedin/AdminDashboard";
import DashboardLayout from "../loggedin/DashboardLayout";

import LoggingIn from "../Login/LogingIn";

const BackendLayout = () => {
  return (
      <Routes>
        <Route path="/" element={<Landingpage />} />
        <Route path="/login" element={<LoggingIn />} />
        {CountryRouter()}
        <Route element={<DashboardLayout />} >
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/admindashboard" element={<AdminDashboard />} />
          <Route path="/links" element={<Weblinks />} />
          <Route path="/tips" element={<TravelTips />} />
        </Route>
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
  );
};

export default BackendLayout;
