const firebaseConfig = {
    apiKey: "AIzaSyA33e48UO1kEJgZ7Mh0vmy3n0_BzhF0dLI",
    authDomain: "mynomadplace-live.firebaseapp.com",
    projectId: "mynomadplace-live",
    storageBucket: "mynomadplace-live.appspot.com",
    messagingSenderId: "450858841865",
    appId: "1:450858841865:web:9779c355e5e55c059d45d8",
    measurementId: "G-8SP74NKP62"
  };

export default firebaseConfig