import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const FooterBar = () => {
  return (
    <Container fluid className="footerContainer footerBar" style={{ backgroundColor: 'black', color: 'white', padding: '1rem' }}>
      <Row>
        <Col md={6} className="d-flex justify-content-start align-items-center">
          <div style={{ borderRight: '1px solid white', paddingRight: '20px' }}>
            Terms and Conditions
          </div>
          <div style={{ marginLeft: '20px' }}>Privacy Policy</div>
          <div style={{ marginLeft: '20px' }}>Blog</div>
        </Col>
        <Col md={6} className="d-flex justify-content-end align-items-center">
          <div>Mynomadplace, All Rights Reserved</div>
        </Col>
      </Row>
    </Container>
  );
};

export default FooterBar;
