import { Row, Col, Container } from "react-bootstrap";
import { ReactComponent as BlogsAndLinksIcon } from "@serie3/mynomadplace/assets/images/landingpage/blogsAndLinksIcon.svg";
import { ReactComponent as CountriesIcon } from "@serie3/mynomadplace/assets/images/landingpage/countriesIcon.svg";
import { ReactComponent as TraveltipsIcon } from "@serie3/mynomadplace/assets/images/landingpage/traveltipsIcon.svg";
import { Trans } from "react-i18next";

const NumberSection = () => {
  return (
    <Container
      fluid
      style={{
        height: "25rem",
        backgroundColor: "#242424",
        marginTop: "4.25rem",
        color: "white",
      }}
    >
      <Row className="h-100">
        <Col className="d-flex justify-content-center align-items-center">
          <div className="d-flex justify-content-around w-100">
            <Row>
              <Col>
                <Row>
                  <Col className="d-flex justify-content-center align-items-center">
                    <CountriesIcon />
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center align-items-center poserNumber">
                    175
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center align-items-center poserText">
                    <Trans i18nKey={"Numbers.Country"}>Countries</Trans>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col className="d-flex justify-content-center align-items-center">
                    <TraveltipsIcon />
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center align-items-center poserNumber">
                    66
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center align-items-center poserText">
                    <Trans i18nKey={"Numbers.TravelTips"}>Travel Tips</Trans>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col className="d-flex justify-content-center align-items-center">
                    <BlogsAndLinksIcon />
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center align-items-center poserNumber">
                    175
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center align-items-center poserText">
                    <Trans i18nKey={"Numbers.BlogsAndLinks"}>
                      Blogs and Links
                    </Trans>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NumberSection;
