import { Row, Col, Card } from "react-bootstrap";
import HeroSections from "./HeroSections";
import thailand from "@serie3/mynomadplace/assets/images/landingpage/thailand.jpg";
import australien from "@serie3/mynomadplace/assets/images/landingpage/australien.jpg";
import austria from "@serie3/mynomadplace/assets/images/landingpage/oesterreich.jpg";
import Ireland from "@serie3/mynomadplace/assets/images/landingpage/ireland.jpg";
import bali from "@serie3/mynomadplace/assets/images/landingpage/bali.jpg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CountryData = () => {
  const cardStyle = {
    position: "relative",
    width: "100%",
    paddingTop: "100%", // Padding top is equal to 100% of the width, making it a square
    background: "rgba(36, 36, 36, 0.30)",
    backgroundSize: "cover", // Ensures the background covers the available space
    backgroundPosition: "center", // Centers the background image
    backgroundRepeat: "no-repeat", // Prevents the image from repeating
  };

  const cardContentStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "white",
    fontFamily: "Times New Roman",
    fontSize: "2rem",
    fontWeight: "700",
    background: "rgba(36, 36, 36, 0.20)",
    borderRadius: "0.75rem",
    border: "none",
  };

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <HeroSections
      title={t("CountryData.Title")}
      description={t("CountryData.Description")}
    >
      <Row>
        <Col md={6} className="d-flex">
          <Card
            style={{
              ...cardStyle,
              backgroundImage: `url(${australien})`,
              borderRadius: "0.75rem",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/country/Australia");
            }}
          >
            <div style={cardContentStyle}>
              {t("CountryData.Australia.Name")}
            </div>
          </Card>
        </Col>
        <Col md={6}>
          <Row>
            <Col md={6} className="d-flex">
              <Card
                style={{
                  ...cardStyle,
                  backgroundImage: `url(${bali})`,
                  borderRadius: "0.75rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/country/Indonesia");
                }}
              >
                <div style={cardContentStyle}>{t("CountryData.Indonesia.Name")}</div>
              </Card>
            </Col>
            <Col md={6} className="d-flex">
              <Card
                style={{
                  ...cardStyle,
                  backgroundImage: `url(${thailand})`,
                  borderRadius: "0.75rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/country/Thailand");
                }}
              >
                <div style={cardContentStyle}>{t("CountryData.Thailand.Name")}</div>
              </Card>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={6} className="d-flex">
              <Card
                style={{
                  ...cardStyle,
                  backgroundImage: `url(${austria})`,
                  borderRadius: "0.75rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/country/Austria");
                }}
              >
                <div style={cardContentStyle}>{t("CountryData.Austria.Name")}</div>
              </Card>
            </Col>
            <Col md={6} className="d-flex" style={{ borderRadius: "0.75rem" }}>
              <Card
                style={{
                  ...cardStyle,
                  backgroundImage: `url(${Ireland})`,
                  borderRadius: "0.75rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/country/Ireland");
                }}
              >
                <div style={cardContentStyle}>{t("CountryData.Ireland.Name")}</div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </HeroSections>
  );
};

export default CountryData;
